import BaseController from '@/service/BaseService'
import MenuRequest from '@/request/System/MenuRequest'

const base = new BaseController()

export default {
  data () {
    return {
      Scene: 0, // 0表示弹框为添加，1表示弹框为修改
      dialogVisible: false,
      showDialog: false,
      // 表格加载
      tableLoad: false,
      // 表单信息
      form: {
        parent_id: 0,
        name: '',
        path: '',
        tableName: '',
        order: 0,
        icon: 'refresh-right',
        rule: 1,
        scene: 1,
        status: 1
      },
      // 表单验证规则
      rules: {
        name: [{
          required: true,
          message: '请输入名称'
        }],
        tableName: [{
          required: true,
          message: '请输入表名'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        icon: [{
          required: true,
          message: '请输入图标'
        }]
      },
      // 分页信息[前端控制单页数量]
      pageData: {
        page: 1,
        limit: 6000,
        total: 0,
        list: []
      },

      // 表单筛选
      searchForm: {
        name: ''
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new MenuRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
    },
    // 取消事件
    cancelDialog: async function () {
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 搜索事件
    searchClick: async function () {
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      this.showDialog = true
      this.Scene = 1
      this.form.scene = 1
      this.form.parent_id = 0
    },
    // 编辑事件
    editClick: async function (item) {
      await base.editClick(item)
    },
    addAuthClick: function (row) {
      this.showDialog = true
      this.Scene = 1
      this.form.scene = 3
      this.form.parent_id = row.id
    },
    // 添加子类
    addSonClick: function (row) {
      this.showDialog = true
      this.form.scene = 2
      this.Scene = 1
      this.form.parent_id = row.id
    }
  }
}
