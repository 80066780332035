<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
          <el-form-item label="名称" prop="name">
            <el-input placeholder="请输入名称" v-model="searchForm.name"></el-input>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <el-button size="mini" class="el-icon-plus" type="primary" @click="addClick" plain>新增</el-button>
      <el-button size="mini" class="el-icon-search" @click="searchClick">搜索</el-button>
      <el-button size="mini" class="el-icon-refresh-right" @click="resetClick">重置</el-button>
    </div>
    <!-- 表头按钮区域开始 -->
    <!-- 弹窗功能 -->
    <el-dialog
      :title="this.Scene === 1 ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog"
    >
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item v-if="form.scene !== 1" label="上级id" prop="parent_id">
          <el-input disabled v-model="form.parent_id"/>
        </el-form-item>

        <el-form-item label="名称" prop="name">
          <el-input placeholder="请输入名称" v-model="form.name"/>
        </el-form-item>

        <el-form-item label="路径" prop="path" v-if="Scene === 1 && form.scene === 3">
          <el-input placeholder="请输入路径" v-model="form.path"/>
        </el-form-item>

        <el-form-item label="路径" prop="path" v-if="Scene === 2 && form.scene !== 1">
          <el-input placeholder="请输入路径" v-model="form.path"/>
        </el-form-item>

        <el-form-item label="表名" prop="tableName" v-if="Scene === 1 && form.scene === 2">
          <el-input placeholder="请输入表名" v-model="form.tableName"/>
        </el-form-item>

        <el-form-item label="图标" prop="icon" v-if="form.scene !== 3">
          <el-input placeholder="请输入图标" v-model="form.icon"/>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态" style="width: 100%;">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="权限" prop="tableName" v-if="Scene === 1 && form.scene === 2">
          <el-select v-model="form.rule" placeholder="请选择">
            <el-option label="CRUD" :value="1"></el-option>
            <el-option label="无" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" prop="order">
          <el-input placeholder="请输入排序" v-model="form.order"/>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 表格 -->
    <el-table
      :data="pageData.list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      v-loading="tableLoad"
      :default-expand-all="false"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column prop="id" label="编号" width="150"/>
      <el-table-column prop="scene" label="类型">
        <template v-slot="{row}">
          <i v-if="row.scene === 1" class="el-icon-folder-opened" style="color: #00ccff"> 一 级</i>
          <i v-if="row.scene === 2" class="el-icon-tickets" style="color: #ffba00"> 二 级</i>
          <i v-if="row.scene === 3" class="el-icon-attract" style="color: #e60000"> 接 口</i>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称"/>
      <el-table-column prop="path" label="路径"/>
      <el-table-column prop="icon" label="图标" width="80">
        <template v-slot="{row}">
          <i :class="`el-icon-${row.icon}`"></i>
        </template>
      </el-table-column>
      <el-table-column prop="order" label="排序" width="100"/>
      <el-table-column label="操作" width="150">
        <template v-slot="{row}">
          <div>
            <el-button
              v-if="row.scene === 1"
              size="mini"
              type="warning"
              @click="addSonClick(row)">二级
            </el-button>

            <el-button
              v-if="row.scene === 2"
              size="mini"
              type="info"
              @click="addAuthClick(row)">接口
            </el-button>
            <el-button size="mini" @click="editClick(row)">编辑</el-button>
          </div>

          <div>
            <el-button size="mini" @click="deleteClick(row)" type="danger">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import '@/assets/static/css/base.css'
import service from '@/service/System/MenuService'

export default service

</script>
